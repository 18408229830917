<template>
  <div class="page-fix" :style="!isFetching ? `background-image: url(${getBackgroundImage})`:''" :class="{'bg-cover' : isLessDimensionImg}">
    <CRow class="m-0" v-if="!showPasswordInput">
      <CCol class="p-0" id="bg-img" style="max-width: 680px">
        <img
          v-if="!isFetching"
          class="position-fixed d-none"
          :class="isTFW ? 'istfw' : ''"
          :src="getBackgroundImage"
          @load="getImageSize"
          :style="{
            width: `${getScreenSize.width - 17}px`,
            height: `${getScreenSize.height}px`,
          }"
          @error="
            $event.target.src = '/img/new_home_bg.png';
            $event.target.style = '';
          "
          ref="campaign_bg_img"
          alt="bg"
        />
      </CCol>
      <CCol class="p-0">
        <div class="mt-5 col-md-8 ml-auto mr-5">
          <CCard class="border-0 bg-white">
            <CCardBody>
              <div class="mb-2">
                <div class="row">
                  <div class="col-md-4 d-flex justify-content-center">
                    <img
                      class="img-fluid rounded-circle"
                      :src="getLogoImage"
                      @error="$event.target.src = '/img/tflogo.png'"
                      alt="logo"
                    />
                  </div>
                  <div class="col-md-8 d-flex align-items-center mt-3">
                    <h4>
                      {{ getCampaignById.campaign_name }}
                    </h4>
                  </div>
                </div>
                <hr color="text-primary" size="10" />
              </div>
              <div v-if="step === 1">
              <div v-if="!isRegCandidate">
                <ValidationObserver
                  ref="login_campaign"
                  v-slot="{ handleSubmit }"
                >
                  <form @submit.prevent="handleSubmit(checkCandidateExists)">
                    <CRow>
                      <CCol>
                        <CRow class="d-flex align-items-center mb-3">
                          <label
                            name="email_label"
                            class="required col-lg-12 col-md-12"
                          >Enter Email</label>
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              name="email"
                              rules="required|email"
                              v-slot="{ errors }"
                            >
                              <TextInput
                                name="email"
                                v-model="candidate_email.email"
                                @input="handleEmailInput"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                        <CRow class="d-flex align-items-center mb-3">
                          <label
                            class="required col-lg-12 col-md-12"
                          >
                            Confirm Email
                          </label>
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required|email|emailConfirmed:email"
                              v-slot="{ errors }"
                            >
                              <TextInput
                                name="confirm_email"
                                v-model="candidate_email.confirm_email"
                                @input="handleEmailInput"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                    <div class="text-center">
                      <router-link :to='`/${getPathAcronym}`'>
                        <CButton
                          color="primary"
                          variant="outline"
                          class="mr-3 btn-width"
                          >Cancel</CButton
                        >
                      </router-link>
                      <CButton
                        class="bg-primary text-white btn-width"
                        @click="checkCandidateExists"
                        >Next</CButton
                      >
                    </div>
                  </form>
                </ValidationObserver>
              </div>
              </div>
              <div v-if="isRegCandidate && !isAlreadyRegWithCurrentOrg">
                <h5 class="text-center text-primary mb-3">
                  Already Registered
                </h5>
                <p class="font-weight-bold h4 mb-3">
                  Welcome Back
                  <span class="text-primary">{{ candidateName | toTitleCase }}</span>
                </p>
                <CRow class="mb-3">
                  <label class="col-md-3 text-primary">Email ID</label>
                  <CCol md="9">
                    <TextInput
                      name="email"
                      :value="candidateInfo.email"
                      :disabled="true"
                    />
                  </CCol>
                </CRow>
                <div v-if="candidateInfo.is_campaign">
                  <p class="mb-2">
                    You are already registered with
                    <span class="font-weight-bold">{{
                      candidateInfo.org_name || candidateInfo.customer_name
                    }},</span
                    > press Continue to complete campaign registration or Cancel to exit.
                  </p>
                  <div class="my-2 mt-5 text-center">
                    <CButton variant="outline" color="primary" @click="routeToCampaignLogin()">Cancel</CButton>
                    <CButton color="primary ml-3" @click="showPreReg">Continue</CButton>
                  </div>
                </div>

                <!-- TFW campaign – candidate registered with another TF Customer -->
                <div v-else-if="getCampaignById.is_tfw_campaign">
                  <p class="mb-2">
                    Your details are already registered with
                    <span class="font-weight-bold">
                      {{ candidateInfo.org_name || candidateInfo.customer_name }}
                    </span>, a member of the TalentFind Marketplace network – 
                    the global healthcare careers hub 
                  </p>
                  <p>
                    Press Confirm to use your 
                    <b class="font-weight-bold">{{ getOrgNameOrAcronym }}</b>
                     profile to quickly register with Talentfind Marketplace and
                     this campaign or Cancel to exit.
                  </p>
                </div>
                <!-- Non-TFW campaign – candidate registered with another TF Customer -->
                <div v-else-if="!getCampaignById.is_tfw_campaign && candidateInfo?.organisation_id === 148">
                  <p class="mb-2">
                    <span class="font-weight-bold">
                      {{ candidateInfo.org_name || candidateInfo.customer_name }}
                    </span>, is a member of the TalentFind Marketplace network – the global
                    healthcare careers hub. You’re already registered and have a
                    TalentFind profile. 
                  </p>
                  <p>
                    Press Confirm to use this profile to quickly register with 
                    <b class="font-weight-bold">{{ getOrgNameOrAcronym }}</b>
                     and this campaign or Cancel to exit.
                  </p>
                </div>
                <!-- Non-TFW campaign – candidate registered with TFW -->
                <div v-else-if="!(getCampaignById.is_tfw_campaign && candidateInfo?.organisation_id === 148)">
                  <p class="mb-2">
                    <span class="font-weight-bold">
                      {{ candidateInfo.org_name || candidateInfo.customer_name }}
                    </span>, is a member of the TalentFind Marketplace network – the global
                    healthcare careers hub. You’re already registered and have a
                    TalentFind profile. 
                  </p>
                  <p>
                    Press Confirm to use this profile to quickly register with 
                    <b class="font-weight-bold">{{ getOrgNameOrAcronym }}</b>
                     and this campaign or Cancel to exit.
                  </p>
                </div>

                <div class="my-2 text-center" v-if="!candidateInfo.is_campaign">
                  <CButton variant="outline" color="primary" @click="routeToCampaignLogin()">Cancel</CButton>
                  <CButton color="primary ml-3" v-if="isAlreadyRegistered" @click="showPasswordInput = true">Register</CButton>
                  <CButton color="primary ml-3" v-else @click="showPreReg()">Confirm</CButton>
                </div>
              </div>
            </CCardBody>
          </CCard>
        </div>
      </CCol>
    </CRow>
    <div 
      class="d-flex justify-content-center align-items-center mt-5"
      v-if="showPasswordInput">
      <CCard style="width: 500px">
        <CCardBody>
          <CCardTitle 
            class="text-primary text-center h2 mb-4" 
            style="font-weight: 500;">
            Welcome To {{ getOrgNameOrAcronym }}
          </CCardTitle>
          <h3 class="mb-2">
            Hello 
            <span class="text-primary" >
              {{ candidateDetails.candidate_name | toTitleCase }}!
            </span>
          </h3>
          <CRow class="mb-3">
            <CCol md="12" class="font-weigth-bold mb-2">
              Please enter your Password
            </CCol>
            <CCol>
              <ValidationProvider rules="required" v-slot="{ errors }" ref="existingCandidatePassword">
                <TextInput
                  name="password"
                  type="password"
                  :value="candidate_email.password"
                  @input="handleInput"
                  :error="errors[0]"
                />
              </ValidationProvider>
            </CCol>
          </CRow>
          <CRow>
            <CCol class="text-left">
              <CButton
                @click="redirectToForgot"
                color="link"
                class="px-0"
                >Forgot password?</CButton
              >
            </CCol>
          </CRow>
          <CRow class="d-flex justify-content-center">
            <CButton class="btn-secondary mr-3" @click="routeToCampaignLogin()">
              Back
            </CButton>
            <CButton
              class="btn-primary"
              :disabled=isLoading
              @click="onExistingCandidate()"
              >Register</CButton
            >
          </CRow>
        </CCardBody>
      </CCard>
    </div>
  </div>
</template>

<script>
import { extend } from "vee-validate";
import { email, required, confirmed } from "vee-validate/dist/rules";
import { BASE_URL } from "@/service_urls";
import Vue from "vue";

import TextInput from "@/components/reusable/Fields/TextInput";

import { mapActions, mapGetters } from "vuex";

extend("email", { ...email, message: "Invalid email" });
extend("required", { ...required, message: "This field is required" });
extend("emailConfirmed", { ...confirmed, message: "The email addresses entered do not match. Please correct and try again." });

import { LOGIN_URL, getLocalOrgDetail } from "@/helpers/helper";
export default {
  name: "CampaignLogin",
  components: {
    TextInput,
  },
  data() {
    return {
      candidate_email: {},
      isFetching: false,
      candidateInfo: null,
      width: null,
      height : null,
      showPasswordInput: false,
      candidateDetails: null,
      isAlreadyRegistered: false,
      isLoading: false,
      isAlreadyRegWithCurrentOrg: false,
      step: 1,
    };
  },
  computed: {
    ...mapGetters(["getCampaignById", "getCandidateData", "getOrgDetail"]),
    getBackgroundImage() {
      const defaultBackground = "/img/new_home_bg.png";
      if (this.getCampaignById && this.getCampaignById.uuid_background_image_link_to_doc) {
          return `${BASE_URL}${this.getCampaignById.uuid_background_image_link_to_doc.replace("/api/v1", "")}?file=background_image&${new Date().getTime()}`;
      } else {
          return defaultBackground;
      }
    },
    getScreenSize() {
      return {
        width: screen.width,
        height: screen.height,
      };
    },
    getLogoImage() {
      return `${BASE_URL}${this.getCampaignById?.uuid_logo_link_to_doc?.replace(
        "/api/v1",
        ""
      )}?file=logo&${new Date().getTime()}`;
    },
    isTFW() {
      return this.$route.params.org_name === "tfw";
    },
    candidateName() {
      return this.candidateInfo
        ? `${this.candidateInfo?.first_name} ${this.candidateInfo.sur_name}`
        : null;
    },
    isRegCandidate() {
      return this.candidateInfo?.candidate_uid || false;
    },
    isLessDimensionImg() {
      if(this.width && this.height && this.width < 680 && this.height < 752 ) {
        return true;
      }
      return false;
    },
    getCustomerID() {
      return getLocalOrgDetail()?.customer_uid || null;
    },
    getOrgID() {
      return getLocalOrgDetail()?.organisation_id || null;
    },
    getLocationID() {
      return getLocalOrgDetail()?.location_id || null;
    },
    getOrgNameOrAcronym() {
      return  this.getOrgDetail.name ?  this.getOrgDetail.name : this.getPathAcronym.toUpperCase()
    },
    getPathAcronym() {
      let { path_acronym } = getLocalOrgDetail();
      return path_acronym;
    },
  },
  methods: {
    ...mapActions([
      "fetchCampaignById",
      "getRegisteredHospitalsByEmail",
      "showToast",
      "checkCandidateExistInCampaign",
      "registerCommonCandidate",
      "login",
    ]),
    handleImageError(event) {
      event.target.src = "/img/new_home_bg.png";
      event.target.style.width = this.getScreenSize.width + "px";
      event.target.style.height = this.getScreenSize.height + "px";
    },
    handleEmailInput(name, value) {
      Vue.set(this.candidate_email, name, (value?.toLowerCase().trim()))
    },
    showPreReg() {
      this.$emit("candidateData", this.candidateInfo);
    },
    navToLogin() {
      this.$router.push({ path: LOGIN_URL() });
    },
    async checkCandidateExists() {
      const isValid = await this.$refs.login_campaign.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory field!",
        });
        return;
      }
      if (!this.candidate_email["email"]) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Enter valid email!",
        });
        return false;
      }
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(this.candidate_email["email"]).toLowerCase())) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Enter valid email!",
        });
        return false;
      }
      if(this.candidate_email["email"] === this.candidate_email["confirm_email"]) {
        this.step = 0;
        const payload = {
          email: this.candidate_email["email"],
          campaign_id: this.getCampaignById.campaign_id,
        };
        this.candidateInfo = null;
        const finalPayload = {
              email: this.candidate_email["email"],
              customer_uid: this.getCustomerID,
              organisation_uid: this.getOrgID || null,
              check_registered: true
            };
        try {
          const res = await this.getRegisteredHospitalsByEmail(finalPayload);
          if (res.status === 200 && res?.data?.[0]) {
            this.candidateDetails = res?.data?.[0];
            this.isAlreadyRegistered = true;
          } else if(res.response.status === 400) {
            this.checkCandidateExistsInCampaign(payload);
            this.isAlreadyRegWithCurrentOrg = true;
            return
          } else {
            this.candidateDetails = res.data
          }
        } catch (err) {
          console.log(err);
        }
        this.checkCandidateExistsInCampaign(payload);
      } else {
          this.showToast({
            class: "bg-danger text-white",
            message: "The email addresses entered do not match. Please correct and try again.",
          });
          return false;
      };
    },
    async checkCandidateExistsInCampaign(payload) {
      await this.checkCandidateExistInCampaign(payload).then((res) => {
          if (res) {
            let data = {
              is_pre_register: true,
              email: this.candidate_email.email,
              first_name: res?.first_name,
              sur_name: res?.sur_name ?? null,
              phone: res?.phone ?? null,
              candidate_uid: res?.candidate_uid ?? null,
              is_campaign: res?.is_campaign ?? null,
              campaign_pre_reg_candidate_id: res?.campaign_pre_reg_candidate_id ?? null,
              candidate_type_id: res.candidate_type_id ?? null,
              candidate_type: res?.candidate_type?.candidate_type ?? null,
              org_name: res?.org_name ?? null,
              organisation_id: res?.organisation?.organisation_id ?? null,
              register_now: false,
              completed: res?.completed ?? null,
            };
            this.candidateInfo = data;
            if (this.isAlreadyRegWithCurrentOrg) {
              this.$store.commit("SET_CAMPAIGN_CANDIDATE_DETAILS", data);
              // data.step = 2;
              this.$emit("candidateData", data);
            } else if(!this.isRegCandidate) {
              this.$emit("candidateData", data);
            }
          } else {
            const data = {
              is_pre_register: true,
              email: this.candidate_email.email,
              register_now: true,
            };
            this.$emit("candidateData", data);
          }
        });
    },
    getImageSize() {
      const img = new Image();
      img.src = this.$refs.campaign_bg_img?.src;
      img.onload = () => {
          this.width = img.width;
          this.height = img.height;
      };
    },
    handleInput(name, value) {
      Vue.set(this.candidate_email, name, value)
    },
    async onExistingCandidate() {
      this.$refs.existingCandidatePassword.validate();
      const isPasswordEntered = this.candidate_email?.password;

      if (isPasswordEntered) {
        try {
          this.isLoading = true;
          let payload = {
            email: this.candidate_email?.email,
            customer_uid: this.getCustomerID,
            organisation_id: this.getOrgID,
            location_id: this.getLocationID || null,
            password: isPasswordEntered,
          };

          await this.registerCommonCandidate(payload)
          .then(async () => {
            const data = {
              username: this.candidate_email?.email,
              password: isPasswordEntered,
            };
            this.$store.commit("SET_CAMPAIGN_WELCOME_DATA", { 
              org_name: this.getOrgNameOrAcronym || null,
              campaign_name: this.getCampaignById?.campaign_name || null,
              is_campaign: true 
            });

            await this.login(data);
          });
        } catch (error) {
          console.error("An error occurred:", error);
        } finally {
          this.isLoading = false;
        }
      }
    },
    redirectToForgot() {
      this.$router.push({
        path: `${
          this.isAcronymBasedLogin ? `${LOGIN_URL()}/forgot` : "/forgot"
        }`,
      });
    },
    routeToCampaignLogin() {
      this.candidateInfo = {}
      this.candidate_email= {}
      this.showPasswordInput = false
      this.step = 1
    }
  },
  mounted() {
    const {
      params: { campaign_id: cid },
    } = this.$router.currentRoute;
    this.campaign_id = cid || "";
    this.candidateInfo = null;
    this.fetchCampaignById(this.campaign_id);
  },
  filters: {
    toTitleCase(data) {
      return data ? _.startCase(data) : data;
    }
  }
};
</script>

<style lang="scss" scoped>
.bg {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 1084px) {
  #bg-img {
    display: none;
  }
}
.container {
  .card-group {
    .card {
      max-width: 450px;
      @media (min-width: 1000px) {
        margin-top: 50px;
      }
    }
  }
}
.page-fix {
  margin-top: -22px;
  margin-right: -30px;
  margin-left: -30px;
  min-height: 100vh;
  background-color: white;
  background-repeat: no-repeat;
}
.istfw {
  padding-top: 22px;
}
.btn-width {
  width: 120px;
}
.bg-cover {
  background-size: cover;
}
</style>
